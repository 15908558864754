import React, { useState, useEffect, Suspense } from "react";
import "react-quill/dist/quill.snow.css";
import "./App.css";
import {
  Routes,
  Route,
  useLocation,
  HashRouter,
  BrowserRouter,
} from "react-router-dom";
// import Users from "./Component/Pages/Home/Users";
// import AboutUs from "./Component/Pages/AboutUs/AboutUs";
// import Technology from "./Component/Pages/Technology/Technology";
// import Services from "./Component/Pages/Services/Services";
// import ContactUs from "./Component/Pages/ContactUs/ContactUs";
// import Support from "./Component/Pages/Support/Support";
// import SuccessStoriesLanding from "./Component/Pages/Resources/SuccessStories/SuccessStoriesLanding";
// import QaAutomation from "./Component/Pages/Resources/SuccessStories/QaAutomation";
// import CloudMigration from "./Component/Pages/Resources/SuccessStories/CloudMigration";
// import BusinessAnalysis from "./Component/Pages/Resources/SuccessStories/BusinessAnalysis";
// import ApplicationDevelopment from "./Component/Pages/Resources/SuccessStories/ApplicationDevelopment";
// import AdvertisingDomain from "./Component/Pages/Resources/SuccessStories/AdvertisingDomain";
// import BlogsLanding from "./Component/Pages/Resources/Blogs/BlogsLanding";
// import Vaadin from "./Component/Pages/Resources/Blogs/Vaadin";
// import LatestQaTech from "./Component/Pages/Resources/Blogs/LatestQaTech";
// import StaffAugmentation from "./Component/Pages/Services/StaffAugmentation";
// import Rpa from "./Component/Pages/Services/Rpa";
// import Data from "./Component/Pages/Services/Data";
// import ContactCenter from "./Component/Pages/Services/ContactCenter";
// import ArtificialIntelligence from "./Component/Pages/Services/ArtificialIntelligence";
// import ApplicationDevelopmentService from "./Component/Pages/Services/ApplicationDevelopmentService";
// import Careers from "./Component/Pages/Resources/careers/Careers";
// import Web3blockchain from "./Component/Pages/Services/Web3blockchain";
const Users = React.lazy(() => import("./Component/Pages/Home/Users"));
const AboutUs = React.lazy(() => import("./Component/Pages/AboutUs/AboutUs"));
const Technology = React.lazy(() =>
  import("./Component/Pages/Technology/Technology")
);
const Services = React.lazy(() =>
  import("./Component/Pages/Services/Services")
);
const ContactUs = React.lazy(() =>
  import("./Component/Pages/ContactUs/ContactUs")
);
const Support = React.lazy(() => import("./Component/Pages/Support/Support"));
const SuccessStoriesLanding = React.lazy(() =>
  import("./Component/Pages/Resources/SuccessStories/SuccessStoriesLanding")
);
const QaAutomation = React.lazy(() =>
  import("./Component/Pages/Resources/SuccessStories/QaAutomation")
);
const CloudMigration = React.lazy(() =>
  import("./Component/Pages/Resources/SuccessStories/CloudMigration")
);
const BusinessAnalysis = React.lazy(() =>
  import("./Component/Pages/Resources/SuccessStories/BusinessAnalysis")
);
const AdvertisingDomain = React.lazy(() =>
  import("./Component/Pages/Resources/SuccessStories/AdvertisingDomain")
);
const ApplicationDevelopment = React.lazy(() =>
  import("./Component/Pages/Resources/SuccessStories/ApplicationDevelopment")
);
const BlogsLanding = React.lazy(() =>
  import("./Component/Pages/Resources/Blogs/BlogsLanding")
);
const Vaadin = React.lazy(() =>
  import("./Component/Pages/Resources/Blogs/Vaadin")
);
const LatestQaTech = React.lazy(() =>
  import("./Component/Pages/Resources/Blogs/LatestQaTech")
);
const StaffAugmentation = React.lazy(() =>
  import("./Component/Pages/Services/StaffAugmentation")
);
const Rpa = React.lazy(() => import("./Component/Pages/Services/Rpa"));
const Data = React.lazy(() => import("./Component/Pages/Services/Data"));
const ContactCenter = React.lazy(() =>
  import("./Component/Pages/Services/ContactCenter")
);
const ArtificialIntelligence = React.lazy(() =>
  import("./Component/Pages/Services/ArtificialIntelligence")
);
const ApplicationDevelopmentService = React.lazy(() =>
  import("./Component/Pages/Services/ApplicationDevelopmentService")
);
const Careers = React.lazy(() =>
  import("./Component/Pages/Resources/careers/Careers")
);
const Web3blockchain = React.lazy(() =>
  import("./Component/Pages/Services/Web3blockchain")
);

const ContactTest = React.lazy(() =>
  import("./Component/Pages/ContactUs/contactTest")
);

const MarketingAccelerator = React.lazy(() =>
  import("./Component/Pages/Marketing/MarketingAccelerator")
);

const Partnerships = React.lazy(() =>
  import("./Component/Pages/Partnerships/Partnership")
);

const ThankYou = React.lazy(() =>
  import("./Component/Pages/Marketing/ThankYou")
);

const PrivacyPolicy = React.lazy(() =>
  import("./Component/Pages/Privacy/PrivacyPolicy")
);

const IsoCertification = React.lazy(() => import("./Component/Pages/IsoCertification/IsoCertification"));
function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        <Suspense
          fallback={
            <div className="homepage-loader-div">
              <img src="img/Loader.gif" className="homepage-loader" />
            </div>
          }
        >
          <Routes>
            <Route exact path="/" element={<Users />} />
            <Route exact path="/about-fugetron" element={<AboutUs />} />
            <Route exact path="/Support" element={<Support />} />
            <Route exact path="/technology" element={<Technology />} />
            <Route exact path="/Support" element={<Support />} />
            <Route exact path="/contact-us" element={<ContactUs />} />
            <Route
              exact
              path="/success-stories"
              element={<SuccessStoriesLanding />}
            />
            <Route exact path="/qa-automation" element={<QaAutomation />} />
            <Route
              exact
              path="/cloud-migration-success-story/"
              element={<CloudMigration />}
            />
            <Route
              exact
              path="/business-analysis"
              element={<BusinessAnalysis />}
            />
            <Route
              exact
              path="/application-development"
              element={<ApplicationDevelopment />}
            />
            <Route
              exact
              path="/advertising-domain"
              element={<AdvertisingDomain />}
            />
            <Route exact path="/blogs" element={<BlogsLanding />} />
            <Route exact path="/vaadin" element={<Vaadin />} />
            <Route
              exact
              path="/latest-qa-technologies"
              element={<LatestQaTech />}
            />
            <Route exact path="/services" element={<Services />} />
            <Route
              exact
              path="/staff-augmentation"
              element={<StaffAugmentation />}
            />
            <Route exact path="/rpa" element={<Rpa />} />
            <Route exact path="/data" element={<Data />} />
            <Route exact path="/contact-center" element={<ContactCenter />} />
            <Route
              exact
              path="/artificial-intelligence"
              element={<ArtificialIntelligence />}
            />
            <Route
              exact
              path="/services-application-development"
              element={<ApplicationDevelopmentService />}
            />
            <Route exact path="/job-opportunities" element={<Careers />} />
            <Route exact path="/Web3blockchain" element={<Web3blockchain />} />
            <Route exact path="/appdev" element={<ContactTest />} />
            <Route exact path="/marketing-accelerator" element={<MarketingAccelerator />} />
            <Route exact path="/partnerships" element={<Partnerships />} />
            <Route exact path="/mktg-thankyou" element={<ThankYou />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/iso-certificate" element={<IsoCertification />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}
export default App;
